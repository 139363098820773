#projects {
  background-color: $white-color;
  color: $dark-blue-text;
  margin-top: -10rem;
  padding-top: 15rem;

  @include respond(tab-land) {
    margin-top: 0;
    padding-top: 5rem;
  }

  .project-wrapper {
    margin-bottom: 15rem;

    @include respond(phone) {
      margin-bottom: 0rem;
    }

    .row {
      margin-bottom: 8rem;
      @include respond(phone) {
        margin-bottom: 4rem;
      }
    }

    

    &__text {
      text-align: left;

      @include respond(phone) {
        margin-bottom: 2.5rem !important;
      }
      @include respond(tab-land) {
        margin-bottom: 4.8rem;
      }

      &-title {
        font-weight: bold;
        margin-bottom: 1.8rem;
        font-size: $mid-font-size;

        @include respond(phone) {
          font-size: 2rem;
        }
      }

      & p > a {
        color: $secondary-color;
      }
    }

    &__image {
      width: 90%;
      margin: 0 auto;

      @include respond(tab-land) {
        width: 100%;
        margin: 0;
      }

      & .thumbnail {
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        transition: all 0.2s ease-out;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
        transition: 0.5s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
          0.5s box-shadow,
          0.5s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);

        @include respond(phone) {
          border: 1px solid $light-grey;
          box-shadow: none;
          margin-bottom: 3.2rem;
        }
      }
    }
  }
}
